<template>
  <section class="page--selection">
    <div v-if="pageSelectType === 'inbox-notif'">
      <router-link
        to="/inbox"
        class="select--item"
        :class="{ selected: $route.name === 'inbox' || $route.name === 'inbox-detail' }"
      >
        {{ $t('home.sidebarMenu.inbox') }}
      </router-link>
      <router-link
        to="/notifications"
        class="select--item"
        :class="{ selected: $route.name === 'notifications' }"
      >
        {{ $t('home.sidebarMenu.notification') }}
      </router-link>
    </div>
    <div v-else-if="pageSelectType === 'profile-pass'">
      <router-link
        to="/profile"
        class="select--item"
        :class="{ selected: $route.name === 'profile' }"
      >
        {{ $t('home.sidebarMenu.profile') }}
      </router-link>
      <router-link
        to="/change-password"
        class="select--item"
        :class="{ selected: $route.name === 'change-password' }"
      >
        {{ $t('loginRegister.changePass') }}
      </router-link>
      <router-link
        to="/bank-account"
        class="select--item"
        :class="{ selected: $route.name === 'bank-account' }"
      >
        {{ $t('home.sidebarMenu.bankAcc') }}
      </router-link>
    </div>
    <div v-else-if="pageSelectType === 'help-desk'">
      <router-link
        to="/space-manager"
        class="select--item"
        :class="{ selected: $route.name === 'space-manager' }"
      >
        {{ $t('home.managerSection.spaceManager') }}
      </router-link>
      <router-link
        to="/co-renting"
        class="select--item"
        :class="{ selected: $route.name === 'co-renting' }"
      >
        {{ $t('home.managerSection.coRenting') }}
      </router-link>
      <router-link
        to="/corporate-client"
        class="select--item"
        :class="{ selected: $route.name === 'corporate-client' }"
      >
        {{ $t('home.managerSection.corporateClient') }}
      </router-link>
      <router-link
        to="/official-partner"
        class="select--item"
        :class="{ selected: $route.name === 'official-partner' }"
      >
        {{ $t('home.managerSection.officialPartner') }}
      </router-link>
      <router-link
        to="/rentfix-open-api"
        class="select--item"
        :class="{ selected: $route.name === 'rentfix-open-api' }"
      >
        {{ $t('home.managerSection.rentfixOpenApi') }}
      </router-link>
    </div>
    <div v-else-if="pageSelectType === 'help'">
      <div
        class="select--item"
        :class="{ selected: selectedHelp && selectedHelp.url === help.url }"
        @click="selectHelp(help)"
        v-for="(help, index) in helpList"
        :key="`help-${index}`"
      >
        {{ $t(help.title) }}
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState({
      selectedHelp: (state) => state.v2.help.selectedHelp,
      helpList: (state) => state.v2.help.helpList,
    }),
    pageSelectType() {
      if (
        this.$route.name === 'inbox' ||
        this.$route.name === 'inbox-detail' ||
        this.$route.name === 'notifications'
      ) {
        return 'inbox-notif';
      } else if (
        this.$route.name === 'profile' ||
        this.$route.name === 'change-password' ||
        this.$route.name === 'bank-account'
      ) {
        return 'profile-pass';
      } else if (
        this.$route.name === 'space-manager' ||
        this.$route.name === 'co-renting' ||
        this.$route.name === 'corporate-client' ||
        this.$route.name === 'official-partner' ||
        this.$route.name === 'rentfix-open-api'
      ) {
        return 'help-desk';
      } else if (this.$route.name === 'help-index' || this.$route.name === 'help') {
        return 'help';
      } else {
        return null;
      }
    },
  },
  methods: {
    selectHelp(help) {
      this.$store.commit('v2/help/SET_SELECTED_HELP', help);
      this.$router.push({
        path: '/help/' + help.url,
        query: { ...this.$route.query },
      });
    },
  },
};
</script>
